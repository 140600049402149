import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import PropTypes from "prop-types";
import { Spinner } from '@chakra-ui/react'
import HOZText from "components/text/HOZText";
import validator from "validator";
import AuthStep1 from "./components/Step1";
import AuthStep2 from "./components/Step2";
import { HOZShortLogo } from "components/icons/Icons";
import AuthStep3 from "./components/Step3";
import AuthStep4 from "./components/Step4";
import apiHelper from "helpers/apiHelper";

function SignUp(props) {
  const [loading, setLoading] = React.useState(false)
  const [Auth1Error, setAuth1Error] = React.useState("false")
  const [Auth2Error, setAuth2Error] = React.useState("false")
  const [Auth3Error, setAuth3Error] = React.useState("false")


  const [next, setNext] = React.useState(Number(0))
  const [TOS, setTOS] = React.useState(false)
  const [auth, setAuth] = React.useState({
    user: "",
    pass: "",
    fname: "",
    lname: "",
    pNum: "",
  })


  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const history = useHistory();

  return (
    <DefaultAuth illustrationBackground={illustration} >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "30px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            <Flex
              align={"center"}
              gap="10px">
              <HOZShortLogo h="60px" w="40px" me='4px' />
              Create An Account

            </Flex>
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            {next == 0 ?
              <AuthStep1 setAuth={setAuth} auth={auth} setNext={setNext} error={Auth1Error} />
              : ""
            }
            {next == 1 ?

              <AuthStep2 setTOS={setTOS} TOS={TOS} setAuth={setAuth} auth={auth} setNext={setNext} error={Auth2Error} />

              : ""
            }
            {next == 2 ?
              <AuthStep3 setAuth={setAuth} auth={auth} setNext={setNext} error={Auth3Error} />
              : ""
            }
            {next == 3 ?
              <AuthStep4 setAuth={setAuth} auth={auth} setNext={setNext} error={Auth3Error} />
              : ""
            }
            <Flex direction={"row"} mt={10}>
              {
                next > 0 ?
                  <Button
                    fontSize='sm'
                    variant='active'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    onClick={async (e) => {
                      setNext(next - 1)
                    }}>
                    {
                      next > 2 ? "" : <HOZText >Previous</HOZText>

                    }
                  </Button>
                  : ""}
              {
                next < 3 ?
                  <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    _hover={{ bg: "brand.600" }}
                    w='100%'
                    h='50'
                    mb='24px'
                    disabled={loading && (auth.user && auth.pass)}
                    onClick={async (e) => {
                      try {
                        switch (next) {
                          case 0:
                            setLoading(true)
                            if (validator.isEmail(auth.user) && auth.pass.length > 8) {
                              setLoading(false)
                              setNext(next + 1)
                            } else if (!validator.isEmail(auth.user)) {
                              setLoading(false)                              
                              setAuth1Error("Invalid email address")
                            } else {
                              setLoading(false)
                              setAuth1Error("Password must be at least 8 characters")
                            }
                            break;
                          case 1:
                            if (!TOS) {
                              setAuth2Error("You must agree to the terms of service")
                              break;
                            } else {
                              setNext(next + 1)
                            }
                          case 2:
                            setAuth3Error(false)
                            if (!auth.fname || !auth.lname || !auth.pNum) {
                              setAuth3Error("Please fill out all fields")
                              break;
                            } else {
                              setLoading(true)
                              try {
                                await apiHelper.register(auth.user, auth.pass, auth.fname, auth.lname, auth.pNum)
                                setNext(next + 1)
                              } catch (e) {
                                setAuth3Error("Something went wrong")
                                setLoading(false)
                                break;
                              }
                              setLoading(false)
                              break;
                            }
                        }

                      } catch (e) {
                        setAuth3Error("Something went wrong")
                        setLoading(false)
                      }


                    }}>

                    {
                      loading ?
                        <Spinner color='white' />
                        :
                        next == 2 ? <HOZText color="white">Create Account</HOZText> : next < 10 ? <HOZText color="white">Next</HOZText> : ""

                    }
                  </Button>
                  : ""
              }
            </Flex>

          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Already Have an Account?
              <NavLink to='/auth/signin'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}
SignUp.propTypes = {
  token: PropTypes.string,
  setToken: PropTypes.func,
};

export default SignUp;
