// Chakra imports
import { border, Button, Flex, Icon, Image, Input, Select, SkeletonText, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import banner from "assets/img/auth/banner.png";
import { FaPlus, FaTrash } from "react-icons/fa";
import apiHelper from "helpers/apiHelper";
import { useHistory } from "react-router-dom";
import { ReviewButton } from "views/league/user/UserReviewForm";

export default function HOZRefCard(props) {
    const { position, index } = props
    const [applications, setApplications] = useState([])
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const bg = useColorModeValue("white", "navy.700");
    const [disabled, setDisabled] = useState(false)
    const bgItem = useColorModeValue(
        { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
        { bg: "navy.700", boxShadow: "unset" }
    );
    const history = useHistory();

    const getUserinfo = (user) => {
        let value = user.first_name;
        if (user.average_review_score){
            value = `${value} (Review Score: ${user.average_review_score})`
        }
        return value;
    }

    useEffect(() => {
        const load = async () => {
            const apps = await apiHelper.getApplications(position.id);
            setApplications(apps)
            setDisabled(true)
        }
        load()
    }, [])
    // send request to assign ref to game based on ref.id and user.profile.id
    const assignRef = async (refId, posId) => {
        apiHelper.assignReferee(refId, posId).then((res) => { })
    };
    return (
        <Card
            _hover={bgItem}
            bg='transparent'
            boxShadow='unset'
            px='24px'
            py='21px'
            transition='0.2s linear'>
            <Flex direction={{ base: "column" }} justify='center'>
                <Flex position='relative' align='center'>
                    <Image src={banner} w='66px' h='66px' borderRadius='20px' me='16px' />
                    <Flex
                        direction='column'
                        w={{ base: "70%", md: "100%" }}
                        me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>

                        <Text w={"100%"} p={0}
                            fontWeight='700' color='secondaryGray.900' fontSize={{ base: "md" }}
                            textAlign={"left"}
                            style={{
                                border: "none"
                            }}>{position.name}</Text>

                        <Flex
                            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                            align='center' direction="row"
                            w={"100%"}>
                            <Text fontWeight='700' fontSize='md' color={textColorPrimary}>
                                Pay
                            </Text>

                            <Flex
                                me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                                align='center' direction="row"
                                fontWeight='700' color='secondaryGray.900' fontSize={{ base: "md" }} >
                                <Text position={"relative"} ml={2} textAlign={"right"} placeContent={"end"} flexGrow={true} >
                                    $
                                </Text>
                                <Text w={"100%"} p={0}
                                    textAlign={"left"}
                                    style={{
                                        border: "none"
                                    }}>{position.pay_rate}</Text>
                            </Flex>
                        </Flex>
                        {/* <Flex
                            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                            align='center' direction="row" >
                            <Text fontWeight='700' fontSize='md' color={textColorPrimary}>
                                Referee Pay
                            </Text>

                            <Flex
                                me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                                align='center' direction="row"
                                fontWeight='700' color='secondaryGray.900' fontSize={{ base: "md" }} >
                                <Text position={"relative"} ml={2} textAlign={"right"} placeContent={"end"} flexGrow={true}>
                                    $
                                </Text>
                                <Text w={20} p={0}
                                    textAlign={"left"}
                                    style={{
                                        border: "none"
                                    }}>
                                    {get.refs[index].pay ? (Number.parseFloat(get.refs[index].pay) - (get.refs[index].pay * 0.20)).toFixed(2) : 0.00}
                                </Text>
                            </Flex>
                        </Flex> */}

                    </Flex>
                    <Flex
                        me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
                        align='center' direction="column">
                        <Text flexGrow={true} color={textColorPrimary} fontWeight='500' fontSize='md'>
                            <Select w={64} borderRadius="16px"
                                disabled={!position.game_position_application}
                                value={
                                    position.game_position_application
                                }
                                onChange={
                                    (e) => {
                                        assignRef(e.target.value, position.id)
                                    }}>
                                <option selected>
                                    Select
                                </option>
                                {applications.map(application => {
                                    return (<option key={application.id} value={application.id} id={application.id}>
                                        {getUserinfo(application.user_detail)} 
                                    </option>)
                                })}
                            </Select>
                            <ReviewButton position={position} />

                        </Text>
                    </Flex>

                </Flex>
            </Flex >
        </Card >
    );
}
