import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Text,
  Input,
  Textarea,
  Checkbox,
  List,
  ListItem,
  Button,
  Grid,
  Flex,
  Card,
  FormControl,
  FormLabel,
  VStack,
  Avatar,
  Spacer,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import withFormikHOC from "../../../helpers/withFormikHOC";
import {
  CustomUserLeagueReview,
  GamePosition,
} from "../../../helpers/interfacesAuto";
import apiHelper from "../../../helpers/apiHelper";
import { FormikProps } from "formik";
import StarRating from "../../../components/star/StarRating";
// import DebugFormik from "components/DebugFormik";
import DebugFormik from "../../../components/DebugFormik";

interface CustomUserReview {
  review_score?: number;
  review_comment?: string;
}

const CUSTOM_USER_REVIEW_INITIAL_VALUES: CustomUserReview = {
  review_score: 0,
  review_comment: "",
};


function ReviewBox({ reviews }: {reviews: CustomUserLeagueReview[]}) {
  return (
    <VStack spacing={4} align="stretch">
      <Box pt={5} pl={5} pr={5}>
        <Text fontSize="2xl" fontWeight="bold">Previous Reviews</Text>
        {reviews.length === 0 && (<>No previous reviews.</>)}
      </Box>
      {reviews.map((review, index) => (
        <Box key={index} pb={5} pl={5} pr={5}>
          <Flex justifyContent="space-between" alignItems="center" mb={3}>
            <Flex alignItems="center" mr={2}>
              <Avatar name={review.league_name} src={"review.league_icon"} size="sm" mr={2} />
              <Text fontSize="lg" fontWeight="bold">{review.league_name}</Text>
            </Flex>
            <StarRating rating={review.review_score || 0}/>
          </Flex>
          <Text fontSize="md" color="gray.600">{review.review_comment}</Text>
        </Box>
      ))}
    </VStack>
  );
}



export const ReviewButton = ({position}:{position: GamePosition}) => {

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const history = useHistory();
  const handleUserReview = () => {
    const path = `/league/userreview/${position?.id}`
    history.push({
        pathname: path,
    });
  }


  return (
    <>
      {position?.game_position_application_detail && 
        <>
          {position?.game_position_application_detail?.is_ready_for_review === true && <Button
            color={textColor}
            fontSize='sm'
            fontWeight='700'
            onClick={handleUserReview}
          >
            Add Review
          </Button>}
          {position?.game_position_application_detail?.is_ready_for_review === false && <Button
            color={textColor}
            fontSize='sm'
            fontWeight='700'
            onClick={handleUserReview}
          >
            View Profile
          </Button>}
      </>}
    </>
  )
}


const InternalForm = ({
  formikProps,
  gamePosition,
  customUserLeagueReviews,
}: {
  formikProps: FormikProps<CustomUserReview>;
  gamePosition: GamePosition;
  customUserLeagueReviews: CustomUserLeagueReview[];
}) => {
  const { values, errors, touched, handleChange, handleBlur } = formikProps;
  return (
    <>
    <Box pt={{ base: "130px", sm: "120px", md: "160px", xl: "80px" }} maxW="3xl" mx="auto" p={8}>
    <DebugFormik localData={formikProps}/>
    <Card
        px="22px"
        mb={{ base: "0px", "2xl": "20px" }}
        pt={30}
        pb={30}
      >
      <VStack spacing={6}>
        <Text fontSize="2xl" fontWeight="bold">{gamePosition?.game_position_application_detail?.user_detail?.first_name}'s Review Page</Text>
        <Avatar name={gamePosition?.game_position_application_detail?.user_detail?.first_name} src={"values.profile_icon"} size="2xl" />
        <StarRating rating={gamePosition?.game_position_application_detail?.user_detail?.average_review_score || 0}/>
          {gamePosition?.game_position_application_detail?.is_ready_for_review && <>
            <FormControl id="score">
              <FormLabel>Score</FormLabel>
              <Text color={"textColorPrimary"} fontWeight="500" fontSize="md">
                  <StarRating
                    rating={
                      values.review_score || 0
                    }
                    onChange={(rating) =>
                      handleChange("review_score")(`${rating}`)
                    }
                    error={errors.review_score}
                  />
              </Text>
            </FormControl>
            
            <FormControl id="comment">
              <FormLabel>Comment</FormLabel>
              <Textarea
                placeholder="Review Comment"
                name="review_comment"
                value={values.review_comment}
                borderRadius="16px"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* <FormErrorMessage>{errors?.review_comment}</FormErrorMessage> */}
            </FormControl>
            <Button colorScheme="blue" type="submit">
              Submit Review
            </Button>
          </>}
        <Box pt={5}>
          <ReviewBox reviews={customUserLeagueReviews} />
        </Box>
      </VStack>
      </Card>
    </Box>    
    </>
  );
};

const FormikComponent = withFormikHOC(InternalForm, undefined);

const UserReviewForm: React.FC = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [gamePosition, setGamePosition] = useState<GamePosition>();
  const [customUserLeagueReviews, setCustomUserLeagueReviews] = useState<
    CustomUserLeagueReview[]
  >([] as CustomUserLeagueReview[]);
  const { userPositionId } = useParams<any>();
  const [initialErrors, setInitialErrors] = useState<any>({"review_score":"", "review_comment": ""});
  const history = useHistory();



  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const gamePosition: GamePosition = (await apiHelper.getGamePosition(
          userPositionId
        )) as GamePosition;
        setGamePosition(gamePosition);
        const customUserLeagueReviews: CustomUserLeagueReview[] =
          await apiHelper.fetchCustomUserLeagueReview(
            gamePosition?.game_position_application_detail?.user,
            gamePosition?.game_detail?.league_location
          );
        setCustomUserLeagueReviews(customUserLeagueReviews);
      } catch (ex: any) {
        alert("Error loading game.");
      } finally {
        setLoading(false);
      }
    };
    load();
    props.setBrand("Review")
  }, []);

  const handleSubmit = async (values: CustomUserReview) => {
    try {
      const values1: any = {
        ...values,
        league: gamePosition?.game_detail?.league_location__league,
        user: gamePosition?.game_position_application_detail?.user,
      };
      await apiHelper.createCustomUserLeagueReview(values1);
    // Set initial errors and touched state
      history.push(`/league/game/view/${gamePosition?.game}`);
    } catch (ex: any) {
      if (ex.response?.status === 400) {
        const initialErrors = ex.response.data;
        debugger;
        if (initialErrors) {
          setInitialErrors({
            review_score: initialErrors.review_score || [],
            review_comment: initialErrors.review_comment || [],
          });
        }      
      } else {
        const message = `Error submitting form: ${ex.detail}`;
        alert(message);
      }


    }
  };

  return (
    <>
    <FormikComponent
      onSubmit={handleSubmit}
      routeInitialValues={CUSTOM_USER_REVIEW_INITIAL_VALUES}
      loading={loading}
      customUserLeagueReviews={customUserLeagueReviews}
      gamePosition={gamePosition}
      initialErrors={initialErrors}
    />
    </>
  );
};

export default UserReviewForm;
