// This is a file for all the API calls that are made in the app 
//
// Path: src/helpers/apiHelper.ts
import axios from 'axios';
import { AuthenticatedRequest } from './tokenRequest';
import jwt from 'jwt-decode'

const BASE_URL=process.env.REACT_APP_BASE_URL
export default class apiHelper {
    static createLeague = async (league) => {
        let token = localStorage.getItem("access_token")
        let user_id = await jwt(token).user_id
        return await (await AuthenticatedRequest.post(`${BASE_URL}/v2/leagueowner/league/leagues/`, {
            ...league,
            user: user_id,
            status: 1
        })).data
    }
    static assignReferee = async (application_id, position_id) => {
        let position = await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/gamepositions/${position_id}/`)).data
        return await (await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/gamepositions/${position_id}/`, {
            game_position_application: application_id,
            game: position.game,
            status: 1
        })).data
    }
    static deleteLocation = async (location_id) => {
        let location = await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leaguelocations/${location_id}/`)).data
        return await (await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/league/leaguelocations/${location_id}/`, {
            ...location,
            is_deleted: true,
        })).data
    }
    static getLeagueGames = async () => {
        let token = localStorage.getItem("access_token")
        let user_id = await jwt(token).user_id
        let leagues = (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leagues/?user_id=${user_id}`)).data
        let games = []
        for (let i = 0; i < leagues.length; i++) {
            let league = leagues[i]
            let leagueGames = await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/games/?league_location__league_id=${league.id}`)).data
            games.push(...leagueGames)
        }
        return games
    }

    static deleteGame = async (game_id) => {
        let positions = await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/gamepositions/?game_id=${game_id}`)).data
        let game = await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/${game_id}/`)).data
        for (let i = 0; i < positions.length; i++) {
            let applications = await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/gamepositionapplications/?game_position_id=${positions[i].id}`)).data
            for (let j = 0; j < applications.length; j++) {
                await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/gamepositionapplications/${applications[j].id}/`, {
                    ...applications[j],
                    is_deleted: true
                })
            }
            await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/gamepositions/${positions[i].id}/`,
                {
                    ...positions[i],
                    is_deleted: true
                })
        }
        return await (await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/${game_id}/`, {
            ...game,
            is_deleted: true
        })).data
    }
    static updateGame = async (game_id, game) => {
        return await (await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/${game_id}/`, game)).data
    }
    static updatePosition = async (position_id, position) => {
        return await (await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/gamepositions/${position_id}/`, position)).data
    }
    static deletePosition = async (position_id) => {
        return await (await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/gamepositions/${position_id}/`, {
            is_deleted: true
        })).data
    }
    static getApplications = async (position_id) => {
        const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/gamepositionapplications/?game_position_id=${position_id}`)
        return response.data
    }
    static getLeague = async () => {
        let token = localStorage.getItem("access_token")
        let user_id = await jwt(token).user_id
        let leagues = (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leagues/?user_id=${user_id}`)).data
        return leagues[0]
    }
    static createLocation = async (league_id, location) => {
        return await (await AuthenticatedRequest.post(`${BASE_URL}/v2/leagueowner/league/leaguelocations/`, {
            league: league_id,
            ...location
        })).data
    }
    static getUserLeagues = async () => {
        let token = localStorage.getItem("access_token")
        let user_id = await jwt(token).user_id
        let leagues = (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leagues/?user_id=${user_id}`)).data
        return leagues
    }
    static getLeagueLocations = async () => {
        let token = localStorage.getItem("access_token")
        let user_id = await jwt(token).user_id
        console.log({ user_id: user_id })
        let leagues = (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leagues/?user_id=${user_id}`)).data
        var locations = []
        console.log({ leagues: leagues })
        await Promise.all(leagues.map(async (league) => {
            console.log({ id: league.id })
            let leagueLocations = (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leaguelocations/?league_id=${league.id}`)).data
            await Promise.all(leagueLocations.map(async (location) => {
                locations.push(location)
            }))
        }))
        return locations
    }
    static createPosition = async (position) => {
        return await (await AuthenticatedRequest.post(`${BASE_URL}/v2/leagueowner/game/gamepositions/`, position)).data
    }
    static createGame = async (game) => {
        return await (await AuthenticatedRequest.post(`${BASE_URL}/v2/leagueowner/game/games/`, game)).data
    }
    static getOpenGames = async () => {
        const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/games/`)
        if (response.data.length > 0) {
            return response.data
        }
        return []
    }
    static getLocation = async (locationId) => {
        return await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leaguelocations/${locationId}/`)).data
    }
    static getAllLocations = async () => {
        return await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/league/leaguelocations/`)).data
    }
    static getGamePositions = async (gameId) => {
        return await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/gamepositions/?game_id=` + gameId)).data
    }
    static getGamePosition = async (gamePositionId) => {
        return await (await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/gamepositions/${gamePositionId}`)).data
    }
    static getGame = async (gameId) => {
        const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/${gameId}/`)
        if (response.data) {
            return response.data
        }
        return {}
    }
    static login = async (email, password) => {
        try {
            const response = await axios.post(`${BASE_URL}/token/`, {
                email: email,
                password: password
            })
            if (response.data.refresh && response.data.access) {
                await localStorage.setItem("access_token", response.data.access)
                await localStorage.setItem("refresh_token", response.data.refresh)
                return 1
            } else {
                return 0
            }
        } catch (e) {
            return 0
        }
    }
    static getAppliedGames = async () => {
        let token = localStorage.getItem("access_token")
        let decoded = await jwt(token).user_id
        const response = await AuthenticatedRequest.get(`/v2/leagueowner/game/gamepositionapplications/?user_id=${decoded}`)
        if (response.data.length > 0) {
            return response.data
        }
        return []
    }
    static applyToPosition = async (positionId) => {
        let token = localStorage.getItem("access_token")
        let decoded = jwt(token).user_id
        const response = await AuthenticatedRequest.post(`/v2/leagueowner/game/gamepositionapplications/`, {
            user: decoded,
            game_position: positionId,

        })
        if (response.data) {
            return response.data
        }
        return {}
    }
    static withdrawApplication = async (applicationId) => {
        return await (await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/gamepositionapplications/${applicationId}/`, {
            is_deleted: true
        })).data
    }
    static register = async (email, password, firstName, lastName, phone_number) => {
        const response = await axios.post(`${BASE_URL}/v2/user/users/`, {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phone_number
        })
        if (response.data) {
            return response.data
        }
        return {}
    }
    static getUser = async () => {
        let token = localStorage.getItem("access_token")
        let decoded = jwt(token).user_id
        const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/user/users/${decoded}/`)
        if (response.data) {
            return response.data
        }
        return {}
    }

    // static getUserById = async (userId) => {
    //     const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/user/users/${parseInt(userId)}/`)
    //     if (response.data) {
    //         return response.data
    //     }
    //     return {}
    // }

    static submitScore = async (posId, team_one_score, team_two_score) => {
        const pos = await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/game/gamepositions/${posId}/`)
        const response = await AuthenticatedRequest.patch(`${BASE_URL}/v2/leagueowner/game/gamepositions/${posId}/`, {
            team_one_score: team_one_score,
            team_two_score: team_two_score
        })
        console.log(response.data)
        if (response.data) {
            return response.data
        }
    }
    static checkToken = async () => {
        const response = await axios.post(`${BASE_URL}/token/refresh/`, {
            refresh: localStorage.getItem("refresh_token")
        })
        if (response.data.access) {
            await localStorage.setItem("access_token", response.data.access)
            return true
        }
        return false
    }

    static getStripeCheckout = async () => {
        let token = localStorage.getItem("access_token")
        let decoded = jwt(token).user_id
        const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/user/users/${decoded}/stripe/setup/`)
        if (response.data) {
            return response.data
        }
        return {}            
    }

    static createCustomUserLeagueReview = async (review) => {
        const response = await AuthenticatedRequest.post(`${BASE_URL}/v2/leagueowner/customuserleaguereviews/`, review)
        return response.data
   }

   static fetchCustomUserLeagueReview = async (userId, leagueId) => {
    const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/customuserleaguereviews/?user_id=${userId}&league_id=${leagueId}`)
    return response.data
}

   static getUserWithReviews = async (userId) => {
        const response = await AuthenticatedRequest.get(`${BASE_URL}/v2/leagueowner/users/${userId}/`)
        return response.data
    }

}